<template>
  <div class="assessment vh-100">
    <v-container class="py-0 px-0 pb-10" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-card flat tile>
        <v-card-text class="d-flex pb-0">
          <p class="text-h6 mb-0">Inspection for Henderson Mine Workshop</p>
        </v-card-text>
      </v-card>
      <v-card>
        <v-data-table :headers="headers" :items="checks" item-key="name" class="inspection-table" hide-default-footer>
          <template v-slot:[`item.name`]="{ item }">
            <span>{{ item.name }}</span>
          </template>
          <template v-slot:[`item.issues`]="{ item }">
            <v-text-field
              outlined
              v-model="item.issues"
              label=""
              dense
              hide-details
              style="margin: 2px 0; background: #404040; width: 240px"
            ></v-text-field>
          </template>

          <template v-slot:[`item.date`]="{ item }">
            <v-text-field
              outlined
              v-model="item.date"
              label=""
              single-line
              dense
              hide-details
              height="15"
              style="margin: 2px 0; background: #404040"
            ></v-text-field>
          </template>

          <template v-slot:[`item.initials`]="{ item }">
            <v-text-field
              outlined
              v-model="item.imitials"
              label=""
              single-line
              dense
              hide-details
              height="15"
              style="margin: 2px 0; background: #404040"
            ></v-text-field>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn class="ma-2" color="primary"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: [
      {
        text: "Description",
        align: "left",
        sortable: false,
        value: "name",
        class: "no-input",
      },
      { text: "Issues", value: "issues" },
      { text: "Date", value: "date" },
      { text: "Initials", value: "initials" },
    ],
    checks: [
      {
        id: 1,
        name: "Aisles and working areas free from tripping, or slipping hazards",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 2,
        name: "Work tables and surfaces kept clean and orderly",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 3,
        name: "Sharp tools, razor blades, and other dangerous items stored safely",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 4,
        name: "Proper PPE available (safety glasses, gloves, shoes, etc.)",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 5,
        name: "Proper PPE being utilized where required",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 6,
        name: "Clearly marked with operational exit signs",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 7,
        name: "Products/supplies stored in designated areas. Inventory performed and maintained.",
        issues: "",
        initials: "",
        effort: "",
      },
      {
        id: 8,
        name: "Lighting in good repair and sufficient. Bulbs replaced as needed. Light bulbs and tubes shielded as required to prevent shattering.",
        issues: "",
        initials: "",
        effort: "",
      },
    ],
  }),
};
</script>
